// import axios from 'axios'

document.addEventListener('DOMContentLoaded', init)

function init () {
    let formObserverOptions = {
        rootMargin: '0px',
        threshold: 0.2
    }

    var formObserver = new IntersectionObserver(
        entries => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    const { target: form } = entry

                    const script = document.getElementById('recaptcha-script')
                    const { dataset } = script
                    const { src } = dataset
                    if (src && !script.getAttribute('src')) {
                        script.setAttribute('src', src)
                    }

                    initForm(form)
                    formObserver.unobserve(form)
                }
            }
        },
        formObserverOptions
    );

    const forms = document.querySelectorAll('.contact-teaser-form')
    for (let form of Array.from(forms)) {
        formObserver.observe(form);
    }

    const mapNavForms = document.querySelectorAll('.map-nav-form')
    for (let form of mapNavForms) {
        initMapNavForm(form)
    }
}

function initForm (form) {
    form.addEventListener('submit', (e) => {
        e.preventDefault()

        grecaptcha.ready(() => {
            grecaptcha.execute('6LcWO-QUAAAAADlcVokv4nIVuSBUEX5tOck4p92x', {action: 'contactforms'}).then(token => {
                enableFormButton(form, false)
                setFormState(form, 'submitting')

                submitForm(form, token).then(response => {
                    if (response.success) {
                        form.reset()
                        setFormState(form, 'success')
                      } else {
                        setFormState(form, 'error')
                      }
                }).catch(error => {
                    setFormState(form, 'error')
                }).finally(() => {
                    enableFormButton(form, true)
                })
            })
        })
    })
}

function submitForm (form, token) {
    const data = serialize(form)
    data.token = token

    const url = '/contact-form/send'

    const headers = {
        'Content-Type': 'application/json'
    }

    return fetch(url, {
        url,
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    }).then(handleErrors)
}

// state = submitting | success | error
function setFormState (form, state) {
    const classes = ['contact-teaser-form--submitting', 'contact-teaser-form--success', 'contact-teaser-form--error']
    for (let className of classes) {
        if (form.classList.contains(className)) {
            form.classList.remove(className)
        }
    }

    const newClassName = `contact-teaser-form--${state}`
    if (!form.classList.contains(newClassName)) {
        form.classList.add(newClassName)
    }
}

function enableFormButton (form, enabled) {
    const button = form.querySelector('button')
    if (!button) return

    button.disabled = !enabled
}

function initMapNavForm (form) {
    form.addEventListener('submit', (e) => {
        e.preventDefault()
        const link = form.querySelector('.map-nav-form__link')
        if (!link) return

        const urlTemplate = decodeURI(link.href)

        const addressElement = form.querySelector('input[name="address"]')
        if (!addressElement) return

        let address = addressElement.value.replace(/[\/\\\-]+[\d\s]*/g, '')
        address = address.replace(/[ ]+/g, '+')

        const url = encodeURI(urlTemplate.replace('{address}', address))
        window.open(url)

        form.reset()
    })
}

function serialize (form) {
    const data = {}

    // Setup our serialized data
    // var serialized = [];

    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {

        var field = form.elements[i]

        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

        // If a multi-select, get all selections
        if (field.type === 'select-multiple') {
            for (var n = 0; n < field.options.length; n++) {
                if (!field.options[n].selected) continue
                data[field.name] = field.options[n].value
                // serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
            }
        }

        // Convert field data to a query string
        else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            data[field.name] = field.value
            // serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
        }
    }

    // return serialized.join('&');
    return data
};

// function processRecaptcha(form) {
//     return new Promise(function(resolve, reject) {
//         // reCAPTCHA
//         const grecaptchaPromise = grecaptcha.execute('6LcWO-QUAAAAADlcVokv4nIVuSBUEX5tOck4p92x', {action: 'contactforms'});

//         grecaptchaPromise.then(function(token) {
//             var url = '/admin/actions/recaptcha/recaptcha/validate'

//             const headers = {
//                 'Content-Type': 'application/json'
//             }

//             const csrfTokenValue = $(form).find("input[name=CRAFT_CSRF_TOKEN]").first().val();
//             const data = {
//                 token: token,
//                 CRAFT_CSRF_TOKEN: csrfTokenValue
//             }

//             const options = {
//                 url,
//                 method: 'POST',
//                 headers,
//                 data
//             }

//             const recaptchaPromise = axios(options)

//             recaptchaPromise.then(function(response) {
//                 const { data } = response
//                 const { valid} = data;

//                 if (valid) {
//                     resolve("success")
//                 } else {
//                     reject("error processing recaptcha")
//                 }
//             }).catch((error) => {
//                 reject(error)
//             })
//         })
//     })
// }

function handleErrors (response) {
    if (!response.ok) {
        return {
          success: false
        };
    }

    return response.json();
}
