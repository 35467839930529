import Flickity from 'flickity-as-nav-for';
import 'flickity/dist/flickity.min.css'

document.addEventListener("DOMContentLoaded", initCarousels);

function initCarousels () {
    initHeaderSlider()
    initContentCarousels()
}

function initContentCarousels () {
    const carousels = document.querySelectorAll('.carousel');
    carousels.forEach((carousel) => {
        const carouselId = carousel.dataset.carouselId;
        const selectorMain = `\#carousel-${carouselId} .carousel__carousel`;
        // const selectorNav = `\#carousel-${carouselId} .carousel__carousel-nav`;
        var flickityMain = new Flickity(selectorMain, {
            lazyLoad: 1
        });
        // var flickityNav = new Flickity(selectorNav, {
        //     asNavFor: selectorMain,
        //     contain: true,
        //     pageDots: false,
        //     lazyLoad: 1
        // });
    });
}

function initHeaderSlider () {
    const sliders = document.querySelectorAll('.js-header-slider')
    sliders.forEach((slider) => {
        var flickity = new Flickity(slider, {
            contain: true,
            pageDots: true,
            lazyLoad: 1
        });
    })
    window.dispatchEvent(new Event('resize'))
}
