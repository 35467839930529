document.addEventListener('DOMContentLoaded', initDefer)

let burger
// let legacyScriptsLoaded = false

function initDefer () {
    const header = document.querySelector('.header')

    const initObserverOptions  = {
        rootMargin: '0px',
        threshold: 0.5
    }

    const initObserver = new IntersectionObserver(entries => {
        for (let entry of entries) {
            if (!entry.isIntersecting) {
                const { target } = entry
                initObserver.unobserve(target)
                loadScripts()
            }
        }

    }, initObserverOptions)

    initObserver.observe(header)

    burger = document.querySelector('.navbar-toggler')
    burger.addEventListener('mouseenter', loadScripts)
    burger.addEventListener('click', loadScripts)

    const dropdowns = document.querySelectorAll('.dropdown')
    for (let dropdown of dropdowns) {
        dropdown.addEventListener('mouseenter', loadScripts)
    }
}

function loadScripts () {
    burger.removeEventListener('mouseenter', loadScripts)
    burger.removeEventListener('click', loadScripts)

    const deferredScriptElements = document.querySelectorAll('.deferred-script')

    for (let element of Array.from(deferredScriptElements)) {
        loadScript(element)
    }

    // loadLegacyScripts()
}

function loadScript(element) {
    const { dataset } = element
    const { src, initialized } = dataset

    if (src && !element.getAttribute('src')) {
        element.setAttribute('src', src)
    } else if (!src && !initialized) {
        dataset.initialized = true
        eval(element.innerHTML)
    }
}

// loadLegacyScripts () {
//     if (legacyScriptsLoaded) { return }

//     legacyScriptsLoaded = true

//     createAndLoadScript('/theme-marketing/docs/assets/js/jquery.min.js')
//     createAndLoadScript('/theme-marketing/docs/assets/js/popper.min.js')
//     createAndLoadScript('/theme-marketing/dist/toolkit.js')
// }

// function createAndLoadScript (src) {
//     const script = document.createElement('script')
//     script.src = src
//     document.body.appendChild(script)

//     return script
// }
