document.addEventListener("DOMContentLoaded", addFiletypeIcons);

function addFiletypeIcons() {
    const map = {
        'a[href$=".mp3"], a[href$=".mp4"], a[href$=".m4v"], a[href$=".wav"], a[href$=".aiff"]': 'fa-file-audio-o',
        'a[href$=".pdf"]': 'fa fa-file-pdf-o',
        'a[href$=".docx"], a[href$=".doc"]': 'fa fa-file-word-o'
    };

    for (let selector in map) {
        const className = map[selector];
        let links = document.querySelectorAll(selector);
        links.forEach(function(link) {
            addFiletypeIcon(link, className);
        });
    }
}

function addFiletypeIcon(link, className) {
    const text = link.innerText;
    link.innerHTML = `<span class="filetype-icon fa ${className}"></span>${text}`;
}
