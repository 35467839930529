document.addEventListener('DOMContentLoaded', initBlog)

function initBlog() {
    const catLinks = document.querySelectorAll('.js-blog-cats-checkbox')
    for (let catLink of catLinks) {
        initCatLink(catLink)
    }
}

function initCatLink(catLink) {
    catLink.addEventListener('click', () => {
        const { url } = catLink.dataset
        window.location = url
    })
}
