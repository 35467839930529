// import LazyLoad from "vanilla-lazyload"

// document.addEventListener("DOMContentLoaded", initLazyLoad)

// function initLazyLoad () {
//     const lazyLoadInstance = new LazyLoad({
//     })
// }

import Lazyload from 'lazyload'

document.addEventListener('DOMContentLoaded', () => {
    const images = document.querySelectorAll('.lazyload')

    new Lazyload(images, {})
})
