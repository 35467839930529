import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

document.addEventListener('DOMContentLoaded', () => {
    init()
});

function init() {
    const elem = document.querySelector('.grid')
    if (!elem) return

    imagesLoaded(elem, initMasonry)
}

function initMasonry() {
    const elem = document.querySelector('.grid')

    const msnry = new Masonry( elem, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
    })
}
